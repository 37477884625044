
:root{
    --main-color: #fa1e67;
    --color-dark: #1D2231;
    --text-grey: #8390A2;
    --core-color: rgb(255, 146, 17);
 /*---Extra---Test---Colors---*/
    --back-hover:#ccc;
    --core-hover:#ff450d;
    --back-hover:#ff5a0dfa;
    --back-white: #ffffff;
    --back-green: #08e02cef;
    --back-yellow: #fffb20;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    font-family: sans-serif;
}
.hide-img {
    display: none;
}

/*---Main---Content---Section---*/

.main-content {
    margin-left: 0px;
}
header {
    background: var(--back-white);
    justify-content: space-between;
    padding: 2px 0px;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
    position: fixed;
    left: 0px;
    width: 100%;
    top: 0;
    z-index: 100;
}
/* .logo img {
    width: 3em;
    height: 2.5rem;
} */
 .logo {
    font-weight: 700;
    color: var(--core-hover);
 }
.btn-action {
    margin-block: 0.5rem;
}
.btn-action a {
    padding: 5px 5px;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-inline: 0.3rem;
    color: var(--back-white);
    border: 1px solid var(--core-hover); 
    background: var(--core-hover);
    cursor: pointer;
}
header .btn-link {
    align-items: right;
}
.btn-link a {
    width: 0px;
    font-size: 0.8rem;
    padding: 5px 5px; 
    border-radius: 5px;
    margin-inline: 0.3rem;
    color: var(--back-white);
    border: 1px solid var(--core-hover); 
    background: var(--core-hover);
    cursor: pointer;
}
/*---Main---Section------------*/

main {
    margin-top: 60px;
    padding: 0.5rem 0.5rem;
    background: #f1f5f9;
    min-height: calc(100vh - 90px);
}

/*---Back----Grids----Sections-------- */

.back-grid-header {
    display: inline-flex;
    width: 100%;
    min-height: 40px;
    /* box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2); */
}
.back-grid-header-left {
    display: inline-flex;
    min-width: 100px;
    min-height: 35px;
    padding: 5px 15px;
    align-items: right;
    justify-content: center;
    /* box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2); */
}
.back-grid-header-blank {
    width: 86%;
    min-height: 35px;
    /* box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2); */
}
.back-grid-header-right {
    display: inline-flex;
    min-width: 100px;
    min-height: 35px;
    padding: 15px 0px;
    align-items: left;
    justify-content: center;
    /* box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2); */
}
.back-grid {
    width: auto;
    padding: 10px 10px;
    margin: auto;
    /* background-color: #bdffe6; */
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2);
}
.back-grid-middle {
    min-height: 130px;
    padding: 10px 10px;
    margin: 0.1rem;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2);
}
.back-grid-short {
    min-height: 60px;
    padding: 5px 5px;
    margin: 0.1rem;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2);
}
.total-heading {
    font-size: 1.1rem;
    font-weight: 550;
    text-align: center;
    padding: 9px  5px;
    height: 35px;
    border-radius: 15px;
    color: var(--back-white);
    background: var(--text-grey);
}
.heading {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 1rem;
    color: var(--color-dark);
}
.sub-heading {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 550;
    text-align: center;
    align-items: center;
    padding: 8px 5px;
    height: 35px;
    border-radius: 5px;
    color: var(--back-white);
    background: var(--text-grey);
}
.hide-space {
    height: 1rem;
}
.main-heading {
    margin-top: 1.5rem;
    font-size: 1.1rem;
    font-weight: 550;
    text-align: left;
    align-items: center;
    padding: 9px  5px;
    height: 35px;
    border-radius: 5px;
    color: var(--color-dark);
}
p {
    margin-top: 1rem;
    font-size: 1.1rem;
    padding: 0.1rem;
    color: var(--color-dark);
}

/*---------------------------*/

.dash-main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    margin: auto;
    /* background: var(--back-yellow); */
} 
.dash-card-main {
    display: flex;
    min-height: auto;
    padding: 15px 15px;
    margin: 15px 15px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    justify-items: center;
    background: var(--back-white);
    /* background: var(--back-green); */
}
.dash-main-left {
    margin: auto;
    font-size: 2rem;
    font-weight: bolder;
}
.dash-main-left p {
    font-size: 1rem;
    font-weight: 700;
}
.dash-main-right {
    margin: auto;
}
.dash-img img {
    width: 20rem;
    height: 20rem;
    align-items: center;
    border-radius: 50%;
}

 /*----------------------------*/
.dash-double {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    margin-top: 1rem;
} 
.card-double-blank {
    display: flex;
    min-height: 100px;
    padding: 15px;
    border-radius: 5px;
    gap: 1rem;
    text-align: center;
    align-items: center; 
    justify-content: center;
    /* background: var(--back-white); */
}
.card-double {
    display: inline-flexbox;
    min-height: 100px;
    padding: 15px;
    border-radius: 5px;
    text-align: left;
    align-items: center; 
    justify-content: center;
    background: var(--back-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.card-double img {
    width: 19rem;
    height: 19rem; 
    margin-left: 5rem;
    margin-right: 5rem;
    justify-items: center;
    border-radius: 8px;
}

/*-----------------------------*/

.dash-triple {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 1rem;
}
.card-triple {
    min-height: 350px;
    padding: 20px;
    border-radius: 1rem;
    border: var(--text-grey) 2px solid;
    background: var(--back-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.triple-img-outer-box {
    width: 160px;
    height: 170px;
    padding: 20px;
    border-radius: 1rem;
    border: var(--core-hover) 2px solid;
    align-items: center; 
    background: var(--back-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

/*----faq---Section--------------*/
.faq-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* text-decoration: underline 4px; */
}
.faq-heading {
    margin: 1rem 1rem 0rem 1rem;
    font-size: 1.5rem;
    font-weight: 550;
    text-align: center;
    align-items: center;
    padding: 0.65rem 1rem;
    height: 50px;
    border-radius: 8px;
    color: var(--back-white);
    background: var(--text-grey);
}
.quetions-box {
    margin:0.5rem 1rem;
}
.click-img{
    width: 25px;
    height: 25px;
    padding: auto;
    margin: auto;
    border-radius: 50%;
    transition: transform 0.5s ease-in;
}
.question button {
   width: 100%;
   background-color: var(--text-grey);
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 15px 15px;
   border: 2px;
   outline: none;
   font-size: 1.3rem;
   border-radius: 8px;
   color: #fff;  
}
.question p {
    font-size: 1.2rem;
    color: var(--color-dark);
    max-height: 0;
    opacity: 0;
    line-height: 1.5;
    margin-left: 0.1rem;
    overflow: hidden;
    transition: all 0.6s ease;
}
/* Answer Will Show When Toggle */
.question p.show {
    max-height: 200px;
    opacity: 1;
    background: var(--back-white);
    padding: 0px 15px 30px 15px;
}
.question button .click-img.rotate {
    transform: rotate(180deg);
}

/*--Smart--Contracts--Section----*/
/* Scrollbar_Tables_Section */

.scroll-bar {
    width: 100%;
    height: 30%;
    overflow: auto;
    white-space: nowrap;
} 
::-webkit-scrollbar {
    width: 10px;
    height: 4px;
}
::-webkit-scrollbar-track {
    border: 1px solid #bdffe6;
    border-radius: 8px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    border: 1px solid #999;
    border-radius: 8px;
    background-color: #999;
    height: 4px;
}
::-webkit-scrollbar-thumb:hover {
    border: 1px solid #bdffe6;
    border-radius: 8px;
}

/*-----Table--Section-----*/
.smart-grid {
    padding: 1rem;
}
table {
    margin: 0.5rem 0.5rem;
    width: 100%;
    border-collapse: collapse;
    background-color: var(--back-white);
    overflow-x: auto;
}
table thead {
    height: 40px;
    background: var(--text-grey);
} 
thead td {
    font-weight: 540;
    color: var(--back-white);
}
table tr {
    border-bottom: 1px solid rgba( 0, 0, 0, 0.1);
    }
table td {
    border-left: 1px solid rgba( 0, 0, 0, 0.1);
    border-bottom: 1px solid rgba( 0, 0, 0, 0.1);
    }
tbody tr:hover {
    background: var(--text-grey);
    color: var(--back-white);
    }
table tbody tr:last-child{
    border-bottom: none;
    }
 td {
    padding: 15px 5px;
    }
.check {
    color: var(--back-white);
    width: 200px;
    padding: 5px;
    text-decoration: none;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    background: var(--core-hover);
}

/*----Footer----Section---------*/

.dash-footer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    margin-top: 1rem;
}
.dash-card-footer-main {
    display: flex;
    min-height: 300px;
    padding: 2rem;
    margin: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    padding-block: 2rem;
    align-items: flex-start;
    justify-content: space-around;
    background: var(--back-white);
}
.card-outer-box {
    min-height: 170px;
    padding: 20px;
    border-radius: 1rem;
    border: var(--core-hover) 2px solid;
    align-items: center; 
    background: var(--back-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.card-logo-footer {
    width: 120px;
    height: 130px;
    padding: 10px;
    margin: 0;
    border-radius: 5px;
    text-align: center;
    align-items: center; 
    background: var(--back-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.card-logo-footer img{
    width: 100px;
    height: 110px;
}
/* ---------- */
.card-logo {
    width: 120px;
    height: 130px;
    padding: 10px;
    margin: auto;
    border-radius: 5px;
    text-align: center;
    align-items: center; 
    background: var(--back-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.card-logo img{
    width: 100px;
    height: 110px;
}
/* ----------- */
.link-column span {
    font-size: 1.2rem;
}
.link-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    font-size: 1rem;
}
.flex {
    align-items: flex-start;
}

/*---Sub-Footer----Section-------*/

.dash-sub-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.3rem;
    margin-top: 0.1rem;
}
.card-sub-footer {
    min-height: 40px;
    padding: 0.5rem;
}
.copy-right {
    font-size: 0.7rem;
    text-align: right;
    margin-right: 0rem;
}
.social-link img{
    width: 0.8rem;
    height: 0.8rem;
    margin-inline: 0.2rem;
    flex-wrap: nowrap;
    text-align: left;
}

/* ----------End--Ppint----------- */








