
:root{
    --main-color: #fa1e67;
    --color-dark: #1D2231;
    --text-grey: #8390A2;
    --core-color: rgb(255, 146, 17);
    --menu-color:rgb(255, 13, 0);
 /*---Extra---Test---Colors---*/
    --back-hover:#ccc;
    --core-hover:#ff450d;
    --back-hover:#ff5a0dfa;
    --back-white: rgb(255, 255, 255);
    --back-green: #08e02cef;
    --back-yellow: #fffb20;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    font-family: sans-serif;
}

/*---Main---Content---Section---*/

.main-content {

    margin-left: 0rem;
    /* transition: margin-left 300ms; */
}
#nav-toggle {
    display: none;
}

.menu-header {
    min-height: 80px;
    top: 0;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    margin-left: 0rem;
    margin-right: 1.2rem;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    display: flex;
    background-color: var(--back-white);
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1);
}

.back-grid-short-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    min-height: 0px;
    min-height: fit-content;
    padding: 1px 1px;
    margin: 0rem;
    /* background-color: greenyellow; */
    /* background-color: var(--back-green); */
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1);
}
.back-grid-menu-btn-space {
    min-height: 60px;
    min-width: 5.5rem;
    align-items: center;
    justify-items: center;
    padding: 0px 0px;
    background-color: var(--back-white);
    /* background-color: #fa1e67; */
    /* box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1); */
}
.menu-link-btn button {
    margin-top: 0.1rem;
    font-size: 0.75rem;
    min-width: 70px;
    margin-left: 0rem;
    padding: 0.1rem 0.1rem;
    background: var(--back-green);
    border-collapse: collapse;
    border-radius: 1%;
    background: linear-gradient(45deg, var(--back-green), var(--back-yellow));
    border-color: var(--back-green);  
}
.menu-link-btn button:hover {
    background: var(--back-yellow);
    color: var(black);
}
.menu-hide-btn button {
    margin-top: 0.1rem;
    font-size: 0.75rem;
    font-weight: 600;
    min-height: 1.5rem;
    min-width: 70px;
  
    margin-left: 0rem;
    padding: 0.1rem 0.1rem;
    
    /* color: var(--core-hover); */
    /* background: var(--back-green);
    border-collapse: collapse; */
    /* border-radius: 1%;   */
}
.menu-logout-btn button {
    margin-top: 0.1rem;
    font-size: 0.75rem;
    font-weight: 750;
    min-height: 1.5rem;
    color: var(--back-white);
    min-width: 65px;
    margin-left: 0rem;
    padding: 0.1rem 0.1rem;
    background: var(--menu-color);
    border-collapse: collapse;
    border-radius: 1%;
    border-color: var(--menu-color);  
}
/* .user-wrapper, .logo{
    display: flex;
    align-items: center;
} */

/*---Main---Section------------*/

main {
    margin-top: 80px;
    padding: 2rem 1.5rem;
    background: var(--back-white);
    min-height: calc(100vh - 90px);
}

/*---Dashboard---Cards---------*/

.cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    margin-top: 1rem;
} 

/* ---Packages--Activation--- */

.card-check {
    display: grid;
    justify-content:center;
    padding: 0.4rem 1.3rem;
    border-radius: 8px;
    text-align: right;
    /* background: linear-gradient(45deg, var(--text-grey), var(--text-grey)); */
    background: linear-gradient(45deg, var(--core-color), var(--core-hover));
    /* background: linear-gradient(45deg, var(--back-green), var(--text-grey)); */
}
.card-single-header-package {
    margin-top: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--back-white); 
    background: var(--core-hover);
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}
.card-check-gap-first {
    margin-top: 1rem;
}
.card-check-gap-middle {
    margin-top: 1rem;
}
.card-check-gap-last {
    margin-top: 1.5rem;
}
.back-grid-activation {
    display: grid;
    align-items: center;
    justify-items: center;
    min-height: 0px;
    width: 200px;
    padding: 1pa 1px;
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.5);
}
.back-grid-items {
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 120px;
    margin: auto;
    padding: 0.1rem 0.1rem;
    box-shadow: 0 4px 8px 0 rgba( 162, 11, 11, 0.1);
}
.back-grid-slot {
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 0px;
    width: 100px;
    padding: 0.1rem 0.1rem;
    box-shadow: 0 4px 8px 0 rgba( 162, 11, 11, 0.1);
}
.slot-n {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0rem;
    padding: 0.05rem 0.05rem;
    /* padding: 0.1rem 0.1rem; */
}
.slot-type img{
    width: 1rem;
    height: 1.1rem;
    font-size: 1rem;
    margin-bottom: -0.2rem;
    margin-left: 0.2rem;
    padding: 0.05rem 0.05rem;
    /* background: var(--back-yellow); */
}
.slot-amount {
    font-size: 1.5rem;
    font-weight: 900;
    margin-left: 0.2rem;
    padding: 0.2rem 0.2rem;
    border-radius: 10%;
    background: var(--back-white);
}
.c-tikker-logo img {
    margin-top: auto;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.2rem;
    padding: 0.05rem 0.05rem;    
    /* background: var(--back-yellow); */
}
.slot-activation a {
    font-size: 0.80rem;
    font-weight: 600;
    color: var(--color-dark);
    min-width: 70px;
    /* min-height: 20px; */
    margin-left: 0.2rem;
    padding: 0.2rem 0.2rem;
    background: var(--back-green);
    border-radius: 5%;
    border-collapse: collapse;
    border-color: var(--back-green);
    cursor: pointer;
    background: linear-gradient(45deg, var(--back-green), var(--back-yellow));
}
.img-item img{
    width: 1rem;
    height: 1rem;
    margin-left: 1.1rem;
}

/* ---My---Detail-------- */

.card-single {
    display: grid;
    justify-content: center;
    padding: 1rem 1.3rem;
    border-radius: 8px;
    text-align: center;
    /* background: linear-gradient(45deg, var(--text-grey), var(--text-grey)); */
    background: linear-gradient(45deg, var(--core-color), var(--core-hover));
    /* background: linear-gradient(45deg, var(--back-green), var(--text-grey)); */
}
/* .card-single-header-package {
    margin-top: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--back-white); 
    background: var(--core-hover);
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
} */
.card-header-detail  .detail{
    margin-top: 1rem;
    margin-bottom: -2rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--back-white); 
    height: 1.5rem;
    padding: 0.1rem 0.1rem;
    border-radius: 2%;
    background: var(--core-hover);
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.5);
}  
.back-grid-detail {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 250px;
    padding: 0.1rem 0.1rem;
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.5);
}
.back-grid-detail-items {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 240px;
    padding: 0.05rem 0.05rem;
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1);
}
.detail-card-gap-first {
    margin-top: -4.5rem;
}
.detail-card-gap-middle {
    margin-top: 1rem;
}
.detail-card-gap-last {
    margin-bottom: 2rem;
}

.r-link {
    margin-top: 0.1rem;
    font-size: 0.8rem;
    font-weight: 100;
    color: var(--back-white);
}
.copy-link .btn {
    margin-top: 0.1rem;
    width: 3rem;
    height: 1.5rem;
    border: 1px solid var(--color-dark);
    border-radius: 3px;
    background: var(--back-green);
    cursor: pointer;
    } 
.card-line-header {
    margin-top: 0.1rem;
    text-align: center;
    font-size: 1rem; 
    color: var(--color-dark);
     font-weight: 800;
    } 
.card-single-heading {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 1rem; 
    }
.sp-id {
    margin-top: 0.4rem;
    font-size: 0.8rem;
    font-weight: 300;
    color: var(--back-white); 
    }
.sp-w {
    margin-top: 0.4rem;
    font-size: 0.7rem;
    font-weight: 500;  
    color: var(--back-white); 
    }
.my-id {
    margin-top: 0.4rem;
    font-size: 0.8rem;
    font-weight: 300;
    color: var(--back-white); 
    }
.my-w {
    margin-top: 0.4rem;
    font-size: 0.7rem;
    font-weight: 500;  
    color: var(--back-white);   
    }
.my-rank {
    margin-top: 0.4rem;
    font-size: 1rem;
    font-weight: 500;  
    color: var(--back-white);   
    }
.c-logo img {
    width: 45px;
    height: 50px;
    margin-right: 0.62rem;
}
.c-logo-grid img {
    width: 22.5px;
    height: 25px;
    margin-right: 0.62rem;
}
.arrow-logo-grid img {
    width: 60px;
    height: 25px;
    margin-right: 0.62rem;
}
.c-name {
    margin-top: -0.6rem;
    margin-right: 0.6rem;
    font-weight: 700;
    font-size: 1rem;
    color: var(--back-white);
    } 
.c-value {
    margin-top: 0.5rem;
    margin-right: 1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--back-white);
    }  


/* -----Total----Income----- */

.card-income-header {
    margin-top: 1rem;
    color: var(--color-dark);
    text-align: center;
    font-size: 1rem; 
    } 
.total-income {
    margin-top: 0.4rem;
    font-size: 1.2rem;
    font-weight: 500;  
    color: var(--back-white);  
}
.reffer-income {
    margin-top: 0.4rem;
    font-size: 1.2rem;
    margin-left: 0rem;
    font-weight: 500;
    color: var(--back-white);
    padding: 0.1rem 0.1rem;
}
.level-income {
    margin-top: 0.4rem;
    font-size: 1.2rem;
    margin-left: 0rem;
    font-weight: 500;
    color: var(--back-white);
    padding: 0.1rem 0.1rem;
}
.direct-reffer {
    margin-top: 0.4rem;
    font-size: 1.2rem;
    margin-left: 0rem;
    font-weight: 500;
    color: var(--back-white);
    padding: 0.1rem 0.1rem;
}
.community-team {
    margin-top: 0.4rem;
    font-size: 1.2rem;
    margin-left: 0rem;
    font-weight: 500;
    color: var(--back-white);
    padding: 0.1rem 0.1rem;
}

/* ----Rank----Income------------- */

.card-rank-income {
    display: grid;
    justify-content:center;
    padding: 0.4rem 1rem;
    border-radius: 8px;
    justify-items: center;
    /* background: linear-gradient(45deg, var(--text-grey), var(--text-grey)); */
    background: linear-gradient(45deg, var(--core-color), var(--core-hover));
    /* background: linear-gradient(45deg, var(--back-green), var(--text-grey)); */
}
.card-single-header .rank-r {
    margin-top: 1rem;
    /* margin-bottom: -1rem; */
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--back-white); 
    width: 220px;
    height: 1.5rem;
    padding:  0.1rem 0.1rem;
    border-radius: 2%;
    background: var(--core-hover);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
} 
.back-grid-calc {
    margin-top: 0.3rem;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 220px;
    padding: 0.1rem 0.1rem;
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}
.back-grid-core {
    margin-top: 0.3rem;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 210px;
    padding: 0.1rem 0.1rem;
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.rank-card-gap-first {
    margin-top: 0.75rem;
}
.rank-card-gap-middle {
    margin-top: 1rem;
}
.rank-card-gap-last {
    margin-bottom: 1.5rem;
}
.rank-r {
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0rem;
    color: var(--back-white);
}
.rank-h {
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0.5rem;
    padding: 0rem 0.25rem;
    color: var(--back-white);
}
.rank {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.5rem;
    padding: 0.05rem 0.05rem;
}
.rank-i {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.5rem;
    padding: 0.05rem 0.05rem;
    color: var(--back-white);
    /* padding: 0.1rem 0.1rem; */
}
.c-icon {
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-dark);
    margin-left: 0.5rem;
    padding: 0.05rem 0.05rem;
    /* padding: 0.1rem 0.1rem; */
}

/*---Table---Pool---Sections------*/

.table-pool {
    width: 100%;
    border-collapse: collapse;
}
.table-pool th, .table-pool td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
}
.table-pool th {
    background: var(--text-grey);
    color: #ffffff;
}
.table-pool tbody tr:hover {
    background: none;
    color: var(--color-dark);
}
.table-pool tr img{
    align-items: center;
    justify-content: center;
    margin-inline: 0.5rem 0.5rem 0.5rem;
}
.img-item img{
    width: 1rem;
    height: 1rem;
}

/*---ID---Search---Section--------*/

.card-search {
    display: grid;
    justify-content:center;
    padding: 0.3rem 0.3rem;
    border-radius: 8px;
    text-align: center;
    background: var(--text-grey);
    background: linear-gradient(45deg, var(--text-grey), var(--text-grey));
    /* background: linear-gradient(45deg, var(--core-color), var(--core-hover)); */
    /* background: linear-gradient(45deg, var(--back-green), var(--text-grey)); */
}
.search-id {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: 700;
    font-size: 1rem;
    }
.myid {
    font-size: 15px;
    width: 80px;
    height: 25px;
    padding: 0px;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    background: var(--back-green);
    cursor: pointer;
    }
.search {
    align-items: center !important;
    }    
.search input {
    margin-block: 5px;
    width: 100%;
    height: 30px;
    padding: 0 40px;
    font-size: 16px;
    margin-bottom: -10px;
    outline: none;
    border: none;
    border-radius: 30px;
    background: #f5f5f5;
    }
.team-id-search {
    font-size: 15px;
    width: 80px;
    height: 25px;
    padding: 0px;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    background: var(--back-green);
    cursor: pointer;
}
.dash-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 1rem;

} 
.total-heading {
    font-size: 1.1rem;
    font-weight: 550;
    text-align: center;
    padding: 9px  5px;
    height: 35px;
    border-radius: 15px;
    color: var(--back-white);
    background: var(--text-grey);
}
.sub-heading {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 550;
    text-align: center;
    align-items: center;
    padding: 8px 5px;
    height: 35px;
    border-radius: 5px;
    color: var(--back-white);
    background: var(--text-grey);
}
.value {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 550;
    text-align: center;
    text-align: center;
    padding: 9px 5px;
    height: 35px;
    border-radius: 5px;
    color: black;
    background-color: #ccc;
}

/*---Tables---Sections-----------*/

table {
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    overflow-x: auto;
}   
table thead {
    background: var(--text-grey);
} 
thead td {
    font-weight: 600;
    color: var(--back-white);
}
table tr {
    border-bottom: 1px solid rgba( 0, 0, 0, 0.1);
    }
table td {
    border-left: 1px solid rgba( 0, 0, 0, 0.1);
    border-bottom: 1px solid rgba( 0, 0, 0, 0.1);
    }
tbody tr:hover {
    background: var(--text-grey);
    color: #fff;
    }
table tbody tr:last-child{
    border-bottom: none;
    }
 td {
    padding: 50px 5px;
    align-items: center;
    }

/* Scrollbar_Tables_Section */

.scroll-bar {
    width: 100%;
    height: 30%;
    overflow: auto;
    white-space: nowrap;
} 
::-webkit-scrollbar {
    width: 10px;
    height: 4px;
}
::-webkit-scrollbar-track {
    border: 1px solid #bdffe6;
    border-radius: 8px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    border: 1px solid #999;
    border-radius: 8px;
    background-color: #999;
    height: 4px;
}
::-webkit-scrollbar-thumb:hover {
    border: 1px solid #bdffe6;
    border-radius: 8px;
}

/*---Back----Grids----Sections-------- */

.back-grid {
    min-height: 350px;
    padding: 10px 10px;
    margin: 0.1rem;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2);
}
.back-grid-middle {
    min-height: 130px;
    padding: 10px 10px;
    margin: 0.1rem;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2);
}
.back-grid-short {
    min-height: 0px;
    padding: 10px 10px;
    margin: 0.1rem;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2);
}
.back-grid-short-simple {
    min-height: 60px;
    padding: 15px 5px;
    margin: 0.1rem;
    /* box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.2); */
}
.heading {
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding: 0.05rem 0.05rem;
    color: var(--core-hover);
}
.hide-space {
    height: 1rem;
}

/*---Check--Level--Section---*/

.check-level {
    font-size: 15px;
    color: var(--back-white);
    min-width: 25px;
    min-height: 25px;
    padding: 5px 5px;
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
    /* background: var(--core-hover); */
} 
.check-level button:hover {
    background: var(--back-yellow);
    color: var(--color-dark);
}  
.click-level {
    font-size: 15px;
    color: var(--back-white);
    min-width: 25px;
    min-height: 25px;
    padding: 5px 5px;
    text-decoration: none;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    background: var(--text-grey);
} 


/* ---Extra---Table---Point------- */

.table-responsive {
    width: 100%;
    overflow-x: auto;
}
table {
    border-collapse: collapse;
}
thead tr {
    border: 1px solid #f0f0f0;
    border-bottom: 2px solid #f0f0f0;
}
thead td {
    font-weight: 700;
}
td {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
}

/* ---Community--Tree------ */

.back-grid-short-tree {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding: 5px 5px;
    margin: 0.1rem;
    /* background-color: #999; */
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1);
}
.back-grid-tree-id-search {
    min-height: 0px;
    width: 250px;
    padding: 1px 1px;
    margin: 0rem;
    /* background-color: greenyellow; */
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1);
}
.back-grid-tree-blank {
    min-height: 60px;
    width: 0.5px;
    padding: 1px 1px;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1);
}
.back-grid-tree-id {
    min-height: 60px;
    width: 50px;
    align-items: center;
    justify-items: center;
    padding: 5px 5px;
    margin: 0.1rem;
    /* background-color: #fa1e67; */
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.1);
}
.tree-img img{
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    /* background: var(--back-yellow); */
}
.tree-id {
    font-size: 0.8rem;
    align-items: center;
    color: var(--core-hover);
    padding: 0.05rem 0.05rem;
    font-weight: 300;
    /* background: var(--back-yellow); */
}
.tree-id-btn button {
    margin-top: 0.2rem;
    font-size: 0.75rem;
    min-width: 45px;
    margin-left: 0rem;
    padding: 0.05rem 0.05rem;
    background: var(--back-green);
    border-collapse: collapse;
    border-color: var(--back-green);  
}

/*----Previpus--Next-----*/

.card-next-previous {
    margin-top: 0.5rem;
    min-height: 30px;
    width: 100%;
    padding: 1px 1px;
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0);
}
.pre-btn button {
    margin-top: 0.2rem;
    font-size: 0.75rem;
    min-width: 45px;
    margin-left: 0rem;
    padding: 0.05rem 0.05rem;
    background: var(--back-green);
    border-collapse: collapse;
    border-color: var(--back-green);  
}
.next-btn button {
    margin-top: 0.2rem;
    font-size: 0.75rem;
    min-width: 45px;
    margin-left: 0rem;
    padding: 0.05rem 0.05rem;
    background: var(--back-green);
    border-collapse: collapse;
    border-color: var(--back-green);  
}