
@media only screen and (max-width: 1180px) {

.main-content {
    width: 100%;
    margin-left: 0rem;
}
.main-header {
  margin-top: 1rem;
}
.cards {
    grid-template-columns: repeat(3, 1fr);
}

}

@media only screen and (max-width: 960px) {
.cards {
    grid-template-columns: repeat(2, 1fr);
}

}

@media only screen and (max-width: 767px) {
.cards {
    grid-template-columns: repeat(2, 1fr);
}
.main-content {
    width: 100%;
    margin-left: 0rem;
}

}

@media only screen and (max-width: 579px) {
.cards {
    grid-template-columns: repeat(1, 1fr);
}
.click-btn {
    font-size: 0.7rem;
}
.card-single-header-package {
    justify-items: center;
    }
.card-single-header-detail {
    text-align: right;
}
.back-grid-calc {
    align-items: left;
}
.back-grid-core {
    align-items: left;
}


.card-check-gap-first {
    margin-top: 1rem;
}
.card-check-gap-middle {
    margin-top: 1rem;
}
.card-check-gap-last {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.rank-card-gap-first {
    margin-top: 2rem;
}
.rank-card-gap-middle {
    margin-top: 1rem;
}
.rank-card-gap-last {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.detail-card-gap-first {
    margin-top: 2.5rem;
}
.detail-card-gap-middle {
    margin-top: 1rem;
}
.detail-card-gap-last {
    margin-top: 1rem;
    margin-bottom: 1rem;
}


}



/*--------End-----Point-----------*/





