
@media only screen and (max-width: 1180px) {

.main-content {
    margin-left: 0%;
}
.main-content header {
    width: 100%;
}
.dash-main-left {
    margin: auto;
    font-size: 1rem;
}
.dash-main-right {
    margin: auto;
}
.dash-img img {
    width: 15rem;
    height: 15rem;
    margin: auto;
    align-items: center;
    border-radius: 50%;
}
.hide-img {
    display: block;
    margin-top: 1rem;
}
.card-double img {
    width: 25rem;
    height: 25rem;
    margin-left: 0rem;
    margin-right: 0rem;
}
.faq-title {
    font-size: 1.5rem;
}

}

@media only screen and (max-width: 960px) {
.dash-main {
    grid-template-columns: repeat(1, 1fr);
}
.dash-img img {
    width: 15rem;
    height: 15rem;
}
.dash-main-left {
    font-size: 1rem;
    margin-left: 0rem;
    font-size: 1.5rem;
}
.sub-heading {
    font-size: 0.9rem;
}
.card-double p {
    font-size: 0.9rem;
}
.card-triple p {
    font-size: 0.9rem;
    
}
.card-double img {
    width: 35vw;
    height: 35vw;
    margin-left: 0rem;
    margin-right: 0rem;
}
.faq-title {
    font-size: 1.5rem;
}
.faq-heading {
    font-size: 1.2rem;
    margin: auto;
}
.question button {
    font-size: 1rem;
}
.question p {
    font-size: 1.1rem;
}
}


@media only screen and (max-width: 767px) {
.main-content {
    width: 100%;
    margin-left: 0rem;
}
header {
    width: 100% !important;
    left: 0 !important;
}
.dash-card-main {
    flex-direction: column-reverse;
}
.dash-main-left {
    font-size: 1rem;
    margin: auto;
    /* margin-left: 0rem; */
    font-size: 1.5rem;
}
.dash-double {
    grid-template-columns: repeat(1, 1fr);
} 
.dash-img img {
    width: 15rem;
}
.btn-action {
    margin-block: block;
    flex-wrap: wrap;
}
.card-double-blank {
    flex-wrap: wrap;
}
.card-double img {
    width: 35vw;
    height: 35vw;
    margin-left: 0rem;
    margin-right: 0rem;
}   
.dash-triple {
    grid-template-columns: repeat(1, 1fr);
} 
.dash-footer {
    grid-template-columns: repeat(2, 1fr);
} 
.sub-heading {
   font-size: 1rem;
}
.faq-title {
    font-size: 1.5rem;
}

}

@media only screen and (max-width: 560px) {
main {
    margin-top: 60px;
}
.dash-img img {
    width: 13rem;
    height: 13rem;
}
.dash-main-left {
    font-size: 1rem;
    margin-left: 0rem;
    font-size: 1.5rem;
}
.card-double img {
    width: 35vw;
    height: 35vw;
    margin-left: 0rem;
    margin-right: 0rem;
}
.btn-action a {
    margin-inline: 0.1rem;
}
.faq-title {
    font-size: 1.2rem;
}
.dash-card-footer-main {  
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
    justify-content: center;
}
.card-logo-footer {
    margin: auto;
}
.flex {
    align-items: center;
}
}

@media only screen and (max-width: 479px) {
.dash-img img {
    width: 13rem;
    height: 13rem;
}
.dash-main-left {
    font-size: 1rem;
    margin-left: 0rem;
    font-size: 1.5rem;
}
}

@media only screen and (max-width: 360px) {

.dash-img img {
    width: 40vw;
    height: 40vw;
}
.dash-main-left {
    font-size: 1.5rem;
    margin: auto;
}
.btn-action a {
    width: 150px;
    font-size: 0.7rem;  
}
.copy-right {
    font-size: 0.6rem;
}
.card-double img {
    width: 55vw;
    height: 40vh;
    margin-left: 0rem;
    margin-right: 0rem;
}
.sub-heading{
    font-size: 0.85rem;
}
.faq-title {
    font-size: 1rem;
}
.faq-heading {
    font-size: 1.3rem;
}
.question button {
    font-size: 0.9rem;
}
.question p {
    font-size: 0.9rem;
    margin-left: 0.5rem;
}
.click-img {
    width: 20px;
    height: 20px;
}
.dash-card-footer-main {  
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    justify-content: center;
    padding: 1rem 0.1rem;
    margin: 0.5rem;
}
.card-logo-footer {
    margin: auto;
}
.flex {
    align-items: center;
    justify-content: center;
}
}

@media only screen and (max-width: 240px) {
.dash-main-left {
    font-size: 1.5rem;
    margin: auto;
}
.dash-img img {
width: 40vw;
height: 40vw;
}
.card-double p {
    flex-wrap: wrap;
    font-size: 0.75rem;
}
.card-double img {
    width: 8rem;
    height: 8rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding: 0px 0px;
}
.sub-heading{
    font-size: 0.75rem;
}
.btn-action a {
    min-width: 40vw;
    min-height: 40vh;
    font-size: 0.5rem;
}
}

/*--------End-----Point-----------*/





